<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-card>
            <b-row>
              <b-col>
                <b-form-group label="Tanggal" label-cols-md="3">
                  <b-form-select
                    :options="tanggal"
                    type="text"
                    @change="setPeriode()"
                    v-model="data.tanggal"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Periode" label-cols-md="3">
                  <b-form-select
                    :options="periode"
                    type="text"
                    @change="setPeriode()"
                    v-model="data.bulan"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Tahun" label-cols-md="3">
                  <b-form-select
                    :options="thn"
                    type="text"
                    @change="setPeriode()"
                    v-model="data.tahun"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <!-- <b-col>
                <b-form-group label="Mulai" label-cols-md="3">
                  <date-picker
                    style="width: 100%"
                    disabled
                    v-model="data.peridoAwal"
                    :placeholder="holders(data.peridoAwal)"
                  ></date-picker>
                </b-form-group>
              </b-col> -->
              <b-col>
                <b-form-group label="Akhir" label-cols-md="3">
                  <date-picker
                    style="width: 100%"
                    disabled
                    v-model="data.peridoAkhir"
                    :placeholder="holders(data.peridoAkhir)"
                  ></date-picker>
                </b-form-group>
              </b-col>
              <!-- <b-col>
                <b-button variant="primary mr-1" @click="getData()"
                  >Lihat Laporan</b-button
                > -->
              <!-- <b-button variant="success" @click="getData()"
                  >Simpan Laporan</b-button
                > -->
              <!-- </b-col> -->
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Mitra" label-cols-md="3">
                  <b-form-select
                    :options="mitra"
                    type="text"
                    @change="getKoordinator()"
                    v-model="data.mitraId"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Koordinator" label-cols-md="3">
                  <b-form-select
                    :options="koordinator"
                    type="text"
                    @change="getCabang()"
                    v-model="data.koordinatorId"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Cabang" label-cols-md="3">
                  <b-form-select
                    :options="cabang"
                    type="text"
                    @change="getCapem()"
                    v-model="data.cabangId"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Capem" label-cols-md="3">
                  <b-form-select
                    :options="capem"
                    type="text"
                    v-model="data.capemId"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <!-- <b-col> -->
              <!-- <b-button variant="primary mr-1" @click="getData()"
                  >Lihat Laporan</b-button
                > -->
              <!-- <b-button variant="success" @click="getData()"
                  >Simpan Laporan</b-button
                > -->
              <!-- </b-col> -->
            </b-row>
            <b-row>
              <b-col md="4"
                >Total Gaji Karyawan Periode
                {{ moment(data.peridoAwal).format("LL") }} s/d
                {{ moment(data.peridoAkhir).format("LL") }}</b-col
              >
              <b-col md="4"
                ><strong>Rp. {{ convert(sumGaji) }}</strong></b-col
              >
              <b-col md="2">
                <b-button variant="danger mr-1" @click="reset()" block
                  >Reset</b-button
                >
              </b-col>
              <b-col md="2">
                <b-button variant="primary mr-1" @click="getData()" block
                  >Lihat Laporan</b-button
                >
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Rekap Gaji</strong>
              </h5>
            </template>

            <b-row>
              <b-col md="3">
                <!-- <b-button @click="cetakExcel()" variant="success"
                  >Export Excel</b-button
                > -->
                <!-- <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group> -->
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template>
                  <template #cell(peridoAwal)="item">
                    {{ moment(item.item.peridoAwal).format("LL") }}
                  </template>
                  <template #cell(peridoAkhir)="item">
                    {{ moment(item.item.peridoAkhir).format("LL") }}
                  </template>

                  <template #cell(gaji)="item">
                    {{ convert(item.item.gaji) }}
                  </template>
                  <template #cell(totalAsuransi)="item">
                    {{ convert(item.item.totalAsuransi) }}
                  </template>
                  <template #cell(totalTunjangan)="item">
                    {{ convert(item.item.totalTunjangan) }}
                  </template>
                  <template #cell(totalPotongan)="item">
                    {{ convert(item.item.totalPotongan) }}
                  </template>
                  <template #cell(totalDenda)="item">
                    {{ convert(item.item.totalDenda) }}
                  </template>
                  <template #cell(takeHomePay)="item">
                    Rp . {{ convert(item.item.takeHomePay) }}
                  </template>
                  <template #cell(actions)="item">
                    <b-button
                      variant="secondary"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Print Data'"
                      @click="print(item.item)"
                      ><CIcon name="cil-print" />{{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
export default {
  components: {
    DatePicker,
  },
  name: "laporanCustomer",
  data() {
    return {
      moment,
      mitra: [],
      koordinator: [],
      cabang: [],
      capem: [],
      items: [
        {
          no: "-",
          cus: "-",
          kat: "-",
          pic: "-",
          noIdentitas: "-",
          noTlpn: "-",
          bank: "-",
          noRek: "-",
        },
      ],
      thn: [],
      periode: [
        { value: "01", text: "Januari" },
        { value: "02", text: "Februari" },
        { value: "03", text: "Maret" },
        { value: "04", text: "April" },
        { value: "05", text: "Mei" },
        { value: "06", text: "Juni" },
        { value: "07", text: "Juli" },
        { value: "08", text: "Agustus" },
        { value: "09", text: "September" },
        { value: "10", text: "Oktober" },
        { value: "11", text: "November" },
        { value: "12", text: "Desember" },
      ],
      tanggal:['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
      data: {
        tanggal:"",
        bulan: "",
        tahun: "",
        peridoAwal: "",
        peridoAkhir: "",
        mitraId: "",
        koordinatorId: "",
        cabangId: "",
        capemId: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama Karyawan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "peridoAwal",
          label: "Awal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "peridoAkhir",
          label: "Akhir",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "gaji",
        //   label: "Gaji",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-right",
        // },

        // {
        //   key: "totalTunjangan",
        //   label: "Total Tunjangan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-right",
        // },
        // {
        //   key: "totalPotongan",
        //   label: "Total Potongan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-right",
        // },
        // {
        //   key: "totalAsuransi",
        //   label: "Asuransi",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-right",
        // },
        // {
        //   key: "totalDenda",
        //   label: "Denda",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-right",
        // },
        {
          key: "takeHomePay",
          label: "THP",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "actions",
          label: "",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ['namaKaryawan'],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    sumGaji() {
      let x = 0;
      let a;
      for (a of this.items) {
        x += a.takeHomePay;
      }
      return Math.floor(x);
    },
  },
  mounted() {
    this.data.bulan = moment().format("MM");
    this.data.tahun = moment().format("YYYY");
    this.getoptions();
    this.setPeriode();
    this.getMitra();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getoptions() {
      for (let i = 2000; i < 2050; i++) {
        this.thn.push(i);
      }
    },
    reset(){
     this.data= {
        tangga:"",
        bulan: "",
        tahun: "",
        peridoAwal: "",
        peridoAkhir: "",
        mitraId: "",
        koordinatorId: "",
        cabangId: "",
        capemId: "",
      }
      this.setPeriode()
    },
    holders(x) {
      if (x) {
        return moment(x).format("LL");
      } else {
        return "";
      }
    },
    convert(x) {
      if (x) {
        return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
    },
    setPeriode() {
      let d = this.data.tanggal
      let p = this.data.bulan;
      let t = this.data.tahun;
      if(d && p && t){
        let q = moment(`${t}${p}${d}`).format();
        let r = moment(q).subtract(1, "M").add(1, "days").format();
        this.data.peridoAkhir = q;
        this.data.peridoAwal = r;
        this.getData();
      }
      else if (p && t) {
        let q = moment(`${t}${p}25`).format();
        let r = moment(q).subtract(1, "M").add(1, "days").format();
        this.data.peridoAkhir = q;
        this.data.peridoAwal = r;
        this.getData();
      }else{
        this.getData()      }
    },
    async getMitra() {
      let mitra = await axios.get(ipBackend + "mitra/list");
      // console.log(mitra.data.data)
      this.mitra = mitra.data.data.map((item) => {
        return { ...item, value: item.id, text: item.namaMitra };
      });
      this.mitra.unshift({ value: "", text: "" });
    },
    async getKoordinator() {
      let koordinator = await axios.get(
        ipBackend + "koordinator/listKoordinatorByMitraId/" + this.data.mitraId
      );
      // console.log(koordinator.data.data, 'koor')
      this.koordinator = koordinator.data.data.map((item) => {
        return {
          ...item,
          value: item.koordinatorId,
          text: item.namaKoordinator,
        };
      });
      this.koordinator.unshift({ value: "", text: "" });
    },
    async getCabang() {
      let cabang = await axios.get(
        ipBackend +
          "cabang/listCabangByKoordinatorId/" +
          this.data.koordinatorId
      );
      // console.log(cabang.data.data,'cabang')
      this.cabang = cabang.data.data.map((item) => {
        return { ...item, value: item.cabangId, text: item.namaCabang };
      });
      this.cabang.unshift({ value: "", text: "" });
      this.data.capem = "";
    },
    async getCapem() {
      let capem = await axios.get(
        ipBackend + "capem/listCapemByCabangId/" + this.data.cabangId
      );
      // console.log(capem.data.data, 'capem')
      this.capem = capem.data.data.map((item) => {
        return { ...item, value: item.capemId, text: item.namaCapem };
      });
      this.capem.unshift({ value: "", text: "" });
    },
    async getData() {
      let vm = this;
      vm.tableBusy = true;
      // console.log(vm.data);
      let laporan = await axios.post(ipBackend + "rekapGaji/list", vm.data);
      console.log(laporan.data.data,'rekap');
      vm.items = laporan.data.data.map((item) => {
        return { ...item, takeHomePay: item.dataRekap.takeHomePay };
      });
      vm.totalRows = vm.items.length;
      vm.tableBusy = false;
    },
    print(data) {
      // console.log(data);
      window.open(ipBackend + "downloadExcel/downloadSlipGaji/" + data.id, "_blank");
    },
    cetakExcel() {
      window.open(ipBackend + "report/reportCustomer", "_blank");
    },
  },
};
</script>
